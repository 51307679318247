import {
  PacvueIcon1Pmark,
  PacvueIcon3Pmark,
  PacvueIconAccessibleAccount,
  PacvueIconAccountProfilesSpendsReport,
  PacvueIconActivation,
  PacvueIconActiveExplorer,
  PacvueIconAdd,
  PacvueIconAddAsinAsPatTo,
  PacvueIconAddKeyword,
  PacvueIconAddToAsinTag,
  PacvueIconAddToCampaign,
  PacvueIconAddToKeywordTag,
  PacvueIconAddtotargeting,
  PacvueIconAdgroupReport,
  PacvueIconAdmin,
  PacvueIconAdRevenue,
  PacvueIconAdTag,
  PacvueIconAdvertiserReport,
  PacvueIconAdvertising,
  PacvueIconAfterDislike,
  PacvueIconAfterLike,
  PacvueIconAi,
  PacvueIconAlertNormal,
  PacvueIconAlertNotSet,
  PacvueIconAllExplorer,
  PacvueIconAllgood,
  PacvueIconAllStores,
  PacvueIconAlpha,
  PacvueIconAmazon,
  PacvueIconAmazondsp,
  PacvueIconAmazondspGray,
  PacvueIconAmazonGray,
  PacvueIconAmazonMedium,
  PacvueIconAmazonSmall,
  PacvueIconAnalysis,
  PacvueIconApiKey,
  PacvueIconApply,
  PacvueIconArrowDown,
  PacvueIconArrowDown1,
  PacvueIconArrowDropDown,
  PacvueIconArrowLeft,
  PacvueIconArrowRight,
  PacvueIconArrowRight1,
  PacvueIconArrowUp,
  PacvueIconAsinExpansion,
  PacvueIconAsinReport,
  PacvueIconAsinWarning,
  PacvueIconAskAQuestionToGetInsight,
  PacvueIconAttributionReport,
  PacvueIconAuditAgain,
  PacvueIconAuditCenterTag,
  PacvueIconAuditHistory,
  PacvueIconAuto,
  PacvueIconAuto1,
  PacvueIconAutoRefillRule,
  PacvueIconAutorenew,
  PacvueIconBalance,
  PacvueIconBetaOrange,
  PacvueIconBetaWhite,
  PacvueIconBinding,
  PacvueIconBol,
  PacvueIconBolGray,
  PacvueIconBolMedium,
  PacvueIconBolSmall,
  PacvueIconBrand,
  PacvueIconBrandHaloReport,
  PacvueIconBrandMetricsReport,
  PacvueIconBudgetManager,
  PacvueIconBudgetPlanning,
  PacvueIconBudgetSchedule,
  PacvueIconBudgetScheduling,
  PacvueIconBulkCreationPriceTracker,
  PacvueIconBulkOperation,
  PacvueIconBulkOperation1,
  PacvueIconCalendarSetting,
  PacvueIconCampaignAi,
  PacvueIconCampaignBudgetInsightReport,
  PacvueIconCampaignHourlyReport,
  PacvueIconCampaignOutOfBudgetReport,
  PacvueIconCampaignOutOfDailyBudgetReport,
  PacvueIconCampaignReport,
  PacvueIconCampaignTagReport,
  PacvueIconCampaignTypeSuperWizard,
  PacvueIconCancel,
  PacvueIconCancelReview,
  PacvueIconCategoryIntelligence,
  PacvueIconChangeLive,
  PacvueIconCheck,
  PacvueIconCheck1,
  PacvueIconChevronsRight,
  PacvueIconChewy,
  PacvueIconChewyGray,
  PacvueIconChewyMedium,
  PacvueIconChewySmall,
  PacvueIconCitrus,
  PacvueIconCitrusGray,
  PacvueIconCitrusMedium,
  PacvueIconCitrusSmall,
  PacvueIconClose,
  PacvueIconCloseCircleFill,
  PacvueIconCollapseDetails,
  PacvueIconColumnChart,
  PacvueIconCommerceAlert,
  PacvueIconCommerceAmazon,
  PacvueIconCommerceAmazonGrey,
  PacvueIconCommerceBussiness,
  PacvueIconCommerceCaseComment,
  PacvueIconCommerceCatalog,
  PacvueIconCommerceCategoryReport,
  PacvueIconCommerceCloseSelected,
  PacvueIconCommerceCompetitive,
  PacvueIconCommerceCustomerService,
  PacvueIconCommerceDataDownloadError,
  PacvueIconCommerceDataDownloadStart,
  PacvueIconCommerceDataDownloadSuccess,
  PacvueIconCommerceDigitalShelf,
  PacvueIconCommerceExpand,
  PacvueIconCommerceFinance,
  PacvueIconCommerceFold,
  PacvueIconCommerceInboundUpload,
  PacvueIconCommerceInboundUploadGrey,
  PacvueIconCommerceOpenSelected,
  PacvueIconCommercePacvueTools,
  PacvueIconCommerceReckittReport,
  PacvueIconCommerceSales,
  PacvueIconCommerceSupplyChain,
  PacvueIconCommerceSupplyChainReport,
  PacvueIconCommerceTarget,
  PacvueIconCommerceTargetGray,
  PacvueIconCommerceWalmart,
  PacvueIconCommerceWalmartGrey,
  PacvueIconCompetitive,
  PacvueIconCompetitorAnalysis,
  PacvueIconComplete,
  PacvueIconComponent,
  PacvueIconConfirm,
  PacvueIconContactUs,
  PacvueIconCopy,
  PacvueIconCreativeImage,
  PacvueIconCreativePreview,
  PacvueIconCreativeReport,
  PacvueIconCreatives,
  PacvueIconCriteo,
  PacvueIconCriteoGray,
  PacvueIconCriteoMedium,
  PacvueIconCriteoSmall,
  PacvueIconCrossRetailer,
  PacvueIconCrossRetailerDashboard,
  PacvueIconCrossRetailerGray,
  PacvueIconCrown,
  PacvueIconCustomColumns,
  PacvueIconCustomScenario,
  PacvueIconCustomWidget,
  PacvueIconDailyData,
  PacvueIconDataAlert,
  PacvueIconDataDownload,
  PacvueIconDaypartChartControl,
  PacvueIconDaypartDecBid,
  PacvueIconDaypartIncBid,
  PacvueIconDayParting,
  PacvueIconDayPartingPause,
  PacvueIconDaypartPlacement,
  PacvueIconDaypartSetBid,
  PacvueIconDefaultScenario,
  PacvueIconDelete,
  PacvueIconDesignateBrandTag,
  PacvueIconDetail,
  PacvueIconDetailChart,
  PacvueIconDislike,
  PacvueIconDocument,
  PacvueIconDoordash,
  PacvueIconDoordashGray,
  PacvueIconDoordashMedium,
  PacvueIconDoordashSmall,
  PacvueIconDowncurve,
  PacvueIconDownload,
  PacvueIconDownloadDefaultReport,
  PacvueIconDrag,
  PacvueIconDragIconReposition,
  PacvueIconDspMedium,
  PacvueIconEbay,
  PacvueIconEbayGray,
  PacvueIconEbayMedium,
  PacvueIconEbaySmall,
  PacvueIconEdit,
  PacvueIconEditdetails,
  PacvueIconEditProfile,
  PacvueIconElArrowLeft,
  PacvueIconElArrowRight,
  PacvueIconEmail,
  PacvueIconError,
  PacvueIconErrorTip,
  PacvueIconEvent,
  PacvueIconExclamation,
  PacvueIconExclamationBlue,
  PacvueIconExclamationGrey,
  PacvueIconExecutive1,
  PacvueIconExecutiveCenter,
  PacvueIconExplor,
  PacvueIconExport,
  PacvueIconEye,
  PacvueIconFastallocation,
  PacvueIconFeedback1,
  PacvueIconFilter,
  PacvueIconFilterScenes,
  PacvueIconFloatingWindow,
  PacvueIconFromkeywordtag,
  PacvueIconGainInsights,
  PacvueIconGetInsight,
  PacvueIconGood1,
  PacvueIconHeatMap,
  PacvueIconHelpCenter,
  PacvueIconHome,
  PacvueIconHomereport,
  PacvueIconHomeSolid,
  PacvueIconHourlyData,
  PacvueIconIncreaseBids,
  PacvueIconIncreaseReach,
  PacvueIconInspiration,
  PacvueIconInstacartMedium,
  PacvueIconInstacartSmall,
  PacvueIconInstcart,
  PacvueIconInstcartGray,
  PacvueIconItemHealthReport,
  PacvueIconItemReport,
  PacvueIconKeywordDetail,
  PacvueIconKeywordExpansion,
  PacvueIconKeywordInsightTop1,
  PacvueIconKeywordInsightTop2,
  PacvueIconKeywordInsightTop3,
  PacvueIconKeywordsPerformanceReport,
  PacvueIconKeywordSymbol,
  PacvueIconKnowledge,
  PacvueIconKroger,
  PacvueIconKrogerGray,
  PacvueIconKrogerMedium,
  PacvueIconKrogerSmall,
  PacvueIconLastPeriodData,
  PacvueIconLeader,
  PacvueIconLike,
  PacvueIconLineBarChart,
  PacvueIconLineChart,
  PacvueIconList,
  PacvueIconLoading,
  PacvueIconLocation,
  PacvueIconLog,
  PacvueIconLogo,
  PacvueIconManage,
  PacvueIconManualKw,
  PacvueIconMarketAnalysis,
  PacvueIconMarketShareReport,
  PacvueIconMarkread,
  PacvueIconMegads,
  PacvueIconMenuFold,
  PacvueIconMenuUnfold,
  PacvueIconMercado,
  PacvueIconMercadoGray,
  PacvueIconMercadoMedium,
  PacvueIconMercadoSmall,
  PacvueIconMerge,
  PacvueIconMergeToDealTerm,
  PacvueIconMessage,
  PacvueIconMissingPicture,
  PacvueIconMobile,
  PacvueIconMonthlyRollover,
  PacvueIconMonthlyWeeklyCampaignPerformanceReport,
  PacvueIconMore,
  PacvueIconMorePlatform,
  PacvueIconMoreVertical,
  PacvueIconMt360Small,
  PacvueIconMultilayer,
  PacvueIconNegativeTargeting,
  PacvueIconNoData,
  PacvueIconNoData1,
  PacvueIconNodata11,
  PacvueIconNofillClose,
  PacvueIconNotification,
  PacvueIconNotification1,
  PacvueIconNotOverrideAsin,
  PacvueIconObtainingData,
  PacvueIconOpenNewTab,
  PacvueIconOptimization,
  PacvueIconOptimizeBidMultiplierForSpCampaignSelected,
  PacvueIconOptimizeBidMultiplierForSpCampaignUnSelected,
  PacvueIconOptimizeItemBidForSpAutoCampaignSelected,
  PacvueIconOptimizeItemBidForSpAutoCampaignUnSelected,
  PacvueIconOptimizeKeywordBidForSpManualCampaignSelected,
  PacvueIconOptimizeKeywordBidForSpManualCampaignUnSelected,
  PacvueIconOutOfStockUpcReport,
  PacvueIconOverrideAsin,
  PacvueIconOverview,
  PacvueIconPacvueCollage,
  PacvueIconPacvueDataImpact,
  PacvueIconPacvueHq,
  PacvueIconPagefail,
  PacvueIconPageinprogress,
  PacvueIconPagesuccess,
  PacvueIconPageTypeReport,
  PacvueIconPageWarning,
  PacvueIconPaidsearchaudit,
  PacvueIconPat,
  PacvueIconPc,
  PacvueIconPerfect,
  PacvueIconPerformanceChart,
  PacvueIconPlacementReport,
  PacvueIconPlatform,
  PacvueIconPlatformReport,
  PacvueIconPortfolioReport,
  PacvueIconPriceTracker,
  PacvueIconProductAi,
  PacvueIconProductCenter,
  PacvueIconProductCenterReport,
  PacvueIconProductEligibilityReport,
  PacvueIconProductsPerformanceReport,
  PacvueIconProfileReport,
  PacvueIconPromotion,
  PacvueIconPurchasedProductReport,
  PacvueIconPureRoasReport,
  PacvueIconQueryReport,
  PacvueIconQuickFind,
  PacvueIconRealTimeData,
  PacvueIconRecommdation,
  PacvueIconRedo,
  PacvueIconReduceBids,
  PacvueIconRefresh,
  PacvueIconRefreshDataAndSendAgain,
  PacvueIconRelevant,
  PacvueIconRemove,
  PacvueIconReplay,
  PacvueIconReport,
  PacvueIconResearch,
  PacvueIconResearchNoData,
  PacvueIconReset,
  PacvueIconResultGood,
  PacvueIconRetailerDashboard,
  PacvueIconRightArrow,
  PacvueIconRowHeight,
  PacvueIconRule,
  PacvueIconRuleDetial,
  PacvueIconRuleLibrary,
  PacvueIconRunAgain,
  PacvueIconSaleUnits,
  PacvueIconSam,
  PacvueIconSamGray,
  PacvueIconSamsclubMedium,
  PacvueIconSamsclubSmall,
  PacvueIconSave,
  PacvueIconSbCategoryBenchmarkReport,
  PacvueIconScheduledTask,
  PacvueIconSearch,
  PacvueIconSelectBrand,
  PacvueIconSeller,
  PacvueIconSend,
  PacvueIconSendAgain,
  PacvueIconSetBid,
  PacvueIconShareCenter,
  PacvueIconShowAbbreviateNumber,
  PacvueIconShowFullNumber,
  PacvueIconSideBarDsp,
  PacvueIconSideDisplay,
  PacvueIconSiderBar,
  PacvueIconSkip,
  PacvueIconSkip1,
  PacvueIconSorting,
  PacvueIconSovEditKeyword,
  PacvueIconSovReport,
  PacvueIconSovShare,
  PacvueIconSparkles,
  PacvueIconSpHourlyReport,
  PacvueIconSponsoredDisplay,
  PacvueIconSpread,
  PacvueIconStar,
  PacvueIconStarfill,
  PacvueIconStarred,
  PacvueIconStickynote2,
  PacvueIconStop,
  PacvueIconSubDrag,
  PacvueIconSubmintForReview,
  PacvueIconSuccess,
  PacvueIconSuperAdmin,
  PacvueIconSuperWizardAi,
  PacvueIconSwitch,
  PacvueIconSwitchToPlan,
  PacvueIconSynchronization,
  PacvueIconSystem,
  PacvueIconTagKeywords,
  PacvueIconTagSymbol,
  PacvueIconTarget,
  PacvueIconTargetGrey,
  PacvueIconTargetingReport,
  PacvueIconTargetMedium,
  PacvueIconTargetSmall,
  PacvueIconTipsExclamation,
  PacvueIconTo,
  PacvueIconTopBarArrowDown,
  PacvueIconTopBarDownload,
  PacvueIconTraining,
  PacvueIconUnbind,
  PacvueIconUnStarred,
  PacvueIconUnverified,
  PacvueIconUpcurve,
  PacvueIconUpdateTime,
  PacvueIconUpload,
  PacvueIconUpload1,
  PacvueIconUserManagement,
  PacvueIconUsername,
  PacvueIconUserPosition,
  PacvueIconVector,
  PacvueIconVerified,
  PacvueIconVerify,
  PacvueIconViewHistory,
  PacvueIconViewLog,
  PacvueIconViewSov,
  PacvueIconVisibilityOff,
  PacvueIconWait,
  PacvueIconWalmart,
  PacvueIconWalmartGray,
  PacvueIconWalmartMedium,
  PacvueIconWalmartSmall,
  PacvueIconWarehouseClaimHelp,
  PacvueIconWarning,
  PacvueIconWarning1,
  PacvueIconWarning2,
  PacvueIconWarning3,
  PacvueIconWarning4,
  PacvueIconWarningOrangeTriangle,
  PacvueIconWarningRedTriangle,
  PacvueIconWastedAdSpendReduceBids,
  PacvueIconWidgetAcos,
  PacvueIconWidgetArrowRight,
  PacvueIconWidgetChart,
  PacvueIconWidgetClick,
  PacvueIconWidgetCtr,
  PacvueIconWidgetExpand,
  PacvueIconWidgetList,
  PacvueIconWidgetMore,
  PacvueIconWidgetSetting,
  PacvueIconWidgetShrink,
  PacvueIconWidgetSpend,
  PacvueIconWidgetTable,
  PacvueIconWorkplace,
  PacvueIconYearOverYearDataReport,
  PacvueIconZhihuiDataAlert,
  PacvueIconZhihuiDataDownload,
  PacvueIconZhihuiNotification,
  PacvueIconZhihuiScheduledTask,
  PacvueIconZhihuiSystem
} from "@pacvue/svg-icon"
export default {
  PacvueIcon1Pmark,
  PacvueIcon3Pmark,
  PacvueIconAccessibleAccount,
  PacvueIconAccountProfilesSpendsReport,
  PacvueIconActivation,
  PacvueIconActiveExplorer,
  PacvueIconAdd,
  PacvueIconAddAsinAsPatTo,
  PacvueIconAddKeyword,
  PacvueIconAddToAsinTag,
  PacvueIconAddToCampaign,
  PacvueIconAddToKeywordTag,
  PacvueIconAddtotargeting,
  PacvueIconAdgroupReport,
  PacvueIconAdmin,
  PacvueIconAdRevenue,
  PacvueIconAdTag,
  PacvueIconAdvertiserReport,
  PacvueIconAdvertising,
  PacvueIconAfterDislike,
  PacvueIconAfterLike,
  PacvueIconAi,
  PacvueIconAlertNormal,
  PacvueIconAlertNotSet,
  PacvueIconAllExplorer,
  PacvueIconAllgood,
  PacvueIconAllStores,
  PacvueIconAlpha,
  PacvueIconAmazon,
  PacvueIconAmazondsp,
  PacvueIconAmazondspGray,
  PacvueIconAmazonGray,
  PacvueIconAmazonMedium,
  PacvueIconAmazonSmall,
  PacvueIconAnalysis,
  PacvueIconApiKey,
  PacvueIconApply,
  PacvueIconArrowDown,
  PacvueIconArrowDown1,
  PacvueIconArrowDropDown,
  PacvueIconArrowLeft,
  PacvueIconArrowRight,
  PacvueIconArrowRight1,
  PacvueIconArrowUp,
  PacvueIconAsinExpansion,
  PacvueIconAsinReport,
  PacvueIconAsinWarning,
  PacvueIconAskAQuestionToGetInsight,
  PacvueIconAttributionReport,
  PacvueIconAuditAgain,
  PacvueIconAuditCenterTag,
  PacvueIconAuditHistory,
  PacvueIconAuto,
  PacvueIconAuto1,
  PacvueIconAutoRefillRule,
  PacvueIconAutorenew,
  PacvueIconBalance,
  PacvueIconBetaOrange,
  PacvueIconBetaWhite,
  PacvueIconBinding,
  PacvueIconBol,
  PacvueIconBolGray,
  PacvueIconBolMedium,
  PacvueIconBolSmall,
  PacvueIconBrand,
  PacvueIconBrandHaloReport,
  PacvueIconBrandMetricsReport,
  PacvueIconBudgetManager,
  PacvueIconBudgetPlanning,
  PacvueIconBudgetSchedule,
  PacvueIconBudgetScheduling,
  PacvueIconBulkCreationPriceTracker,
  PacvueIconBulkOperation,
  PacvueIconBulkOperation1,
  PacvueIconCalendarSetting,
  PacvueIconCampaignAi,
  PacvueIconCampaignBudgetInsightReport,
  PacvueIconCampaignHourlyReport,
  PacvueIconCampaignOutOfBudgetReport,
  PacvueIconCampaignOutOfDailyBudgetReport,
  PacvueIconCampaignReport,
  PacvueIconCampaignTagReport,
  PacvueIconCampaignTypeSuperWizard,
  PacvueIconCancel,
  PacvueIconCancelReview,
  PacvueIconCategoryIntelligence,
  PacvueIconChangeLive,
  PacvueIconCheck,
  PacvueIconCheck1,
  PacvueIconChevronsRight,
  PacvueIconChewy,
  PacvueIconChewyGray,
  PacvueIconChewyMedium,
  PacvueIconChewySmall,
  PacvueIconCitrus,
  PacvueIconCitrusGray,
  PacvueIconCitrusMedium,
  PacvueIconCitrusSmall,
  PacvueIconClose,
  PacvueIconCloseCircleFill,
  PacvueIconCollapseDetails,
  PacvueIconColumnChart,
  PacvueIconCommerceAlert,
  PacvueIconCommerceAmazon,
  PacvueIconCommerceAmazonGrey,
  PacvueIconCommerceBussiness,
  PacvueIconCommerceCaseComment,
  PacvueIconCommerceCatalog,
  PacvueIconCommerceCategoryReport,
  PacvueIconCommerceCloseSelected,
  PacvueIconCommerceCompetitive,
  PacvueIconCommerceCustomerService,
  PacvueIconCommerceDataDownloadError,
  PacvueIconCommerceDataDownloadStart,
  PacvueIconCommerceDataDownloadSuccess,
  PacvueIconCommerceDigitalShelf,
  PacvueIconCommerceExpand,
  PacvueIconCommerceFinance,
  PacvueIconCommerceFold,
  PacvueIconCommerceInboundUpload,
  PacvueIconCommerceInboundUploadGrey,
  PacvueIconCommerceOpenSelected,
  PacvueIconCommercePacvueTools,
  PacvueIconCommerceReckittReport,
  PacvueIconCommerceSales,
  PacvueIconCommerceSupplyChain,
  PacvueIconCommerceSupplyChainReport,
  PacvueIconCommerceTarget,
  PacvueIconCommerceTargetGray,
  PacvueIconCommerceWalmart,
  PacvueIconCommerceWalmartGrey,
  PacvueIconCompetitive,
  PacvueIconCompetitorAnalysis,
  PacvueIconComplete,
  PacvueIconComponent,
  PacvueIconConfirm,
  PacvueIconContactUs,
  PacvueIconCopy,
  PacvueIconCreativeImage,
  PacvueIconCreativePreview,
  PacvueIconCreativeReport,
  PacvueIconCreatives,
  PacvueIconCriteo,
  PacvueIconCriteoGray,
  PacvueIconCriteoMedium,
  PacvueIconCriteoSmall,
  PacvueIconCrossRetailer,
  PacvueIconCrossRetailerDashboard,
  PacvueIconCrossRetailerGray,
  PacvueIconCrown,
  PacvueIconCustomColumns,
  PacvueIconCustomScenario,
  PacvueIconCustomWidget,
  PacvueIconDailyData,
  PacvueIconDataAlert,
  PacvueIconDataDownload,
  PacvueIconDaypartChartControl,
  PacvueIconDaypartDecBid,
  PacvueIconDaypartIncBid,
  PacvueIconDayParting,
  PacvueIconDayPartingPause,
  PacvueIconDaypartPlacement,
  PacvueIconDaypartSetBid,
  PacvueIconDefaultScenario,
  PacvueIconDelete,
  PacvueIconDesignateBrandTag,
  PacvueIconDetail,
  PacvueIconDetailChart,
  PacvueIconDislike,
  PacvueIconDocument,
  PacvueIconDoordash,
  PacvueIconDoordashGray,
  PacvueIconDoordashMedium,
  PacvueIconDoordashSmall,
  PacvueIconDowncurve,
  PacvueIconDownload,
  PacvueIconDownloadDefaultReport,
  PacvueIconDrag,
  PacvueIconDragIconReposition,
  PacvueIconDspMedium,
  PacvueIconEbay,
  PacvueIconEbayGray,
  PacvueIconEbayMedium,
  PacvueIconEbaySmall,
  PacvueIconEdit,
  PacvueIconEditdetails,
  PacvueIconEditProfile,
  PacvueIconElArrowLeft,
  PacvueIconElArrowRight,
  PacvueIconEmail,
  PacvueIconError,
  PacvueIconErrorTip,
  PacvueIconEvent,
  PacvueIconExclamation,
  PacvueIconExclamationBlue,
  PacvueIconExclamationGrey,
  PacvueIconExecutive1,
  PacvueIconExecutiveCenter,
  PacvueIconExplor,
  PacvueIconExport,
  PacvueIconEye,
  PacvueIconFastallocation,
  PacvueIconFeedback1,
  PacvueIconFilter,
  PacvueIconFilterScenes,
  PacvueIconFloatingWindow,
  PacvueIconFromkeywordtag,
  PacvueIconGainInsights,
  PacvueIconGetInsight,
  PacvueIconGood1,
  PacvueIconHeatMap,
  PacvueIconHelpCenter,
  PacvueIconHome,
  PacvueIconHomereport,
  PacvueIconHomeSolid,
  PacvueIconHourlyData,
  PacvueIconIncreaseBids,
  PacvueIconIncreaseReach,
  PacvueIconInspiration,
  PacvueIconInstacartMedium,
  PacvueIconInstacartSmall,
  PacvueIconInstcart,
  PacvueIconInstcartGray,
  PacvueIconItemHealthReport,
  PacvueIconItemReport,
  PacvueIconKeywordDetail,
  PacvueIconKeywordExpansion,
  PacvueIconKeywordInsightTop1,
  PacvueIconKeywordInsightTop2,
  PacvueIconKeywordInsightTop3,
  PacvueIconKeywordsPerformanceReport,
  PacvueIconKeywordSymbol,
  PacvueIconKnowledge,
  PacvueIconKroger,
  PacvueIconKrogerGray,
  PacvueIconKrogerMedium,
  PacvueIconKrogerSmall,
  PacvueIconLastPeriodData,
  PacvueIconLeader,
  PacvueIconLike,
  PacvueIconLineBarChart,
  PacvueIconLineChart,
  PacvueIconList,
  PacvueIconLoading,
  PacvueIconLocation,
  PacvueIconLog,
  PacvueIconLogo,
  PacvueIconManage,
  PacvueIconManualKw,
  PacvueIconMarketAnalysis,
  PacvueIconMarketShareReport,
  PacvueIconMarkread,
  PacvueIconMegads,
  PacvueIconMenuFold,
  PacvueIconMenuUnfold,
  PacvueIconMercado,
  PacvueIconMercadoGray,
  PacvueIconMercadoMedium,
  PacvueIconMercadoSmall,
  PacvueIconMerge,
  PacvueIconMergeToDealTerm,
  PacvueIconMessage,
  PacvueIconMissingPicture,
  PacvueIconMobile,
  PacvueIconMonthlyRollover,
  PacvueIconMonthlyWeeklyCampaignPerformanceReport,
  PacvueIconMore,
  PacvueIconMorePlatform,
  PacvueIconMoreVertical,
  PacvueIconMt360Small,
  PacvueIconMultilayer,
  PacvueIconNegativeTargeting,
  PacvueIconNoData,
  PacvueIconNoData1,
  PacvueIconNodata11,
  PacvueIconNofillClose,
  PacvueIconNotification,
  PacvueIconNotification1,
  PacvueIconNotOverrideAsin,
  PacvueIconObtainingData,
  PacvueIconOpenNewTab,
  PacvueIconOptimization,
  PacvueIconOptimizeBidMultiplierForSpCampaignSelected,
  PacvueIconOptimizeBidMultiplierForSpCampaignUnSelected,
  PacvueIconOptimizeItemBidForSpAutoCampaignSelected,
  PacvueIconOptimizeItemBidForSpAutoCampaignUnSelected,
  PacvueIconOptimizeKeywordBidForSpManualCampaignSelected,
  PacvueIconOptimizeKeywordBidForSpManualCampaignUnSelected,
  PacvueIconOutOfStockUpcReport,
  PacvueIconOverrideAsin,
  PacvueIconOverview,
  PacvueIconPacvueCollage,
  PacvueIconPacvueDataImpact,
  PacvueIconPacvueHq,
  PacvueIconPagefail,
  PacvueIconPageinprogress,
  PacvueIconPagesuccess,
  PacvueIconPageTypeReport,
  PacvueIconPageWarning,
  PacvueIconPaidsearchaudit,
  PacvueIconPat,
  PacvueIconPc,
  PacvueIconPerfect,
  PacvueIconPerformanceChart,
  PacvueIconPlacementReport,
  PacvueIconPlatform,
  PacvueIconPlatformReport,
  PacvueIconPortfolioReport,
  PacvueIconPriceTracker,
  PacvueIconProductAi,
  PacvueIconProductCenter,
  PacvueIconProductCenterReport,
  PacvueIconProductEligibilityReport,
  PacvueIconProductsPerformanceReport,
  PacvueIconProfileReport,
  PacvueIconPromotion,
  PacvueIconPurchasedProductReport,
  PacvueIconPureRoasReport,
  PacvueIconQueryReport,
  PacvueIconQuickFind,
  PacvueIconRealTimeData,
  PacvueIconRecommdation,
  PacvueIconRedo,
  PacvueIconReduceBids,
  PacvueIconRefresh,
  PacvueIconRefreshDataAndSendAgain,
  PacvueIconRelevant,
  PacvueIconRemove,
  PacvueIconReplay,
  PacvueIconReport,
  PacvueIconResearch,
  PacvueIconResearchNoData,
  PacvueIconReset,
  PacvueIconResultGood,
  PacvueIconRetailerDashboard,
  PacvueIconRightArrow,
  PacvueIconRowHeight,
  PacvueIconRule,
  PacvueIconRuleDetial,
  PacvueIconRuleLibrary,
  PacvueIconRunAgain,
  PacvueIconSaleUnits,
  PacvueIconSam,
  PacvueIconSamGray,
  PacvueIconSamsclubMedium,
  PacvueIconSamsclubSmall,
  PacvueIconSave,
  PacvueIconSbCategoryBenchmarkReport,
  PacvueIconScheduledTask,
  PacvueIconSearch,
  PacvueIconSelectBrand,
  PacvueIconSeller,
  PacvueIconSend,
  PacvueIconSendAgain,
  PacvueIconSetBid,
  PacvueIconShareCenter,
  PacvueIconShowAbbreviateNumber,
  PacvueIconShowFullNumber,
  PacvueIconSideBarDsp,
  PacvueIconSideDisplay,
  PacvueIconSiderBar,
  PacvueIconSkip,
  PacvueIconSkip1,
  PacvueIconSorting,
  PacvueIconSovEditKeyword,
  PacvueIconSovReport,
  PacvueIconSovShare,
  PacvueIconSparkles,
  PacvueIconSpHourlyReport,
  PacvueIconSponsoredDisplay,
  PacvueIconSpread,
  PacvueIconStar,
  PacvueIconStarfill,
  PacvueIconStarred,
  PacvueIconStickynote2,
  PacvueIconStop,
  PacvueIconSubDrag,
  PacvueIconSubmintForReview,
  PacvueIconSuccess,
  PacvueIconSuperAdmin,
  PacvueIconSuperWizardAi,
  PacvueIconSwitch,
  PacvueIconSwitchToPlan,
  PacvueIconSynchronization,
  PacvueIconSystem,
  PacvueIconTagKeywords,
  PacvueIconTagSymbol,
  PacvueIconTarget,
  PacvueIconTargetGrey,
  PacvueIconTargetingReport,
  PacvueIconTargetMedium,
  PacvueIconTargetSmall,
  PacvueIconTipsExclamation,
  PacvueIconTo,
  PacvueIconTopBarArrowDown,
  PacvueIconTopBarDownload,
  PacvueIconTraining,
  PacvueIconUnbind,
  PacvueIconUnStarred,
  PacvueIconUnverified,
  PacvueIconUpcurve,
  PacvueIconUpdateTime,
  PacvueIconUpload,
  PacvueIconUpload1,
  PacvueIconUserManagement,
  PacvueIconUsername,
  PacvueIconUserPosition,
  PacvueIconVector,
  PacvueIconVerified,
  PacvueIconVerify,
  PacvueIconViewHistory,
  PacvueIconViewLog,
  PacvueIconViewSov,
  PacvueIconVisibilityOff,
  PacvueIconWait,
  PacvueIconWalmart,
  PacvueIconWalmartGray,
  PacvueIconWalmartMedium,
  PacvueIconWalmartSmall,
  PacvueIconWarehouseClaimHelp,
  PacvueIconWarning,
  PacvueIconWarning1,
  PacvueIconWarning2,
  PacvueIconWarning3,
  PacvueIconWarning4,
  PacvueIconWarningOrangeTriangle,
  PacvueIconWarningRedTriangle,
  PacvueIconWastedAdSpendReduceBids,
  PacvueIconWidgetAcos,
  PacvueIconWidgetArrowRight,
  PacvueIconWidgetChart,
  PacvueIconWidgetClick,
  PacvueIconWidgetCtr,
  PacvueIconWidgetExpand,
  PacvueIconWidgetList,
  PacvueIconWidgetMore,
  PacvueIconWidgetSetting,
  PacvueIconWidgetShrink,
  PacvueIconWidgetSpend,
  PacvueIconWidgetTable,
  PacvueIconWorkplace,
  PacvueIconYearOverYearDataReport,
  PacvueIconZhihuiDataAlert,
  PacvueIconZhihuiDataDownload,
  PacvueIconZhihuiNotification,
  PacvueIconZhihuiScheduledTask,
  PacvueIconZhihuiSystem
}
